import styled from '@emotion/styled';
import Container from '@mui/material/Container';

import { BREAKPOINTS, SPACE, COLORS } from '../../constants';
import { H1, P, SectionHeading } from '../type';
import { shouldForwardProp } from '../../utils/should-forward-prop';

interface ImageWrapperProps {
  url?: string;
  hasFocalPoint?: boolean;
  focalPoint?: [number, number];
  textAlignment?: string;
}

interface StyledTextProps {
  textColor?: string;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${SPACE.X3};
`;

const ImageWrapper = styled('div', { shouldForwardProp })<ImageWrapperProps>`
  height: 260px;
  padding-bottom: ${SPACE.X3};
  background-color: ${COLORS.GREY_FOUR};
  background-image: ${({ url }) => `url(${url})`};
  background-repeat: no-repeat;
  background-position: ${({ hasFocalPoint, focalPoint }) =>
    hasFocalPoint && focalPoint ? `${focalPoint[0] * 100}% ${focalPoint[1] * 100}%` : `center`};
  background-size: cover;
  text-align: ${({ textAlignment }) => textAlignment};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    position: absolute;
    inset: 0;
    height: auto;
  }
`;

const Space1 = styled.div`
  margin-top: ${SPACE.X1};
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;

const StyledP = styled(P, { shouldForwardProp })<StyledTextProps>`
  color: ${({ textColor }) => (textColor === 'white' ? COLORS.WHITE : COLORS.BLACK)};
  text-transform: uppercase;
`;

const StyledHeading = styled(H1, { shouldForwardProp })<StyledTextProps>`
  color: ${({ textColor }) => (textColor === 'white' ? COLORS.WHITE : COLORS.BLACK)};
`;

const StyledSectionHeading = styled(SectionHeading, { shouldForwardProp })<StyledTextProps>`
  color: ${({ textColor }) => (textColor === 'white' ? COLORS.WHITE : COLORS.BLACK)};
`;

const BreadcrumbsWrapper = styled.div`
  align-self: flex-start;
  padding: ${SPACE.X2} 0;
`;

const StyledImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 0px;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: 98px;
    height: 98px;
  }
`;

export {
  Wrapper,
  ImageWrapper,
  Space1,
  StyledContainer,
  StyledP,
  StyledHeading,
  StyledSectionHeading,
  BreadcrumbsWrapper,
  StyledImg,
};
