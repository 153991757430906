import { CURATED_FILTERS } from '@/constants/curated-filters';

export const getCuratedFilterUrl = (filter: keyof typeof CURATED_FILTERS) => {
  const { query } = CURATED_FILTERS[filter];
  const queryObj: Record<string, string> = {};

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      queryObj[key] = value.join(',');
    } else {
      queryObj[key] = String(value);
    }
  });

  const searchParams = new URLSearchParams(queryObj);
  return `/results?${searchParams.toString()}`;
};
