// stylelint-disable property-no-vendor-prefix
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { connectHits } from 'react-instantsearch-dom';

import { useClickOutside } from '../../hooks/use-click-outside';
import { BOX_SHADOW, BREAKPOINTS, COLORS, FONT_SIZE, GLOBAL, SPACE } from '../../constants';
import { navigateToVehiclePage } from '../../utils/api';
import { logTrackingEvent } from '../../utils/tracking';
import { iconNames } from '../icons';
import { shouldForwardProp } from '../../utils/should-forward-prop';

import { ErrorText, OptionIcon, SearchIcon } from './styles';

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 999;
`;

const StyledForm = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${SPACE.X1};

  input {
    padding-left: ${SPACE.X3};
    @media (min-width: ${BREAKPOINTS.LG}px) {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    padding-top: ${SPACE.X0};
    padding-bottom: ${SPACE.X0};
  }
`;

const StyledOption = styled('button', { shouldForwardProp })`
  background-color: transparent;
  border: none;
  color: ${COLORS.GREY_ELEVEN};
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 2rem;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  font-size: ${FONT_SIZE.X_LARGE};
  line-height: ${FONT_SIZE.X_LARGE};
  text-align: left;

  &:hover {
    background-color: ${COLORS.BRAND_BLUE_FOUR};
  }

  ${({ isSelected }) => (isSelected ? `background-color: ${COLORS.BRAND_BLUE_SIX};` : '')}
`;

const StyledSelect = styled.input`
  appearance: none;
  -webkit-appearance: none;
  background-color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.BRAND_BLUE_SIX};
  border-radius: 0;
  box-shadow: ${BOX_SHADOW.DEFAULT};
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  font-size: ${FONT_SIZE.X_LARGE};
  outline: 1px solid transparent;
  padding: 0.5rem 2rem;
  width: 100%;
`;

const OptionContainer = styled.div`
  background: white;
  border: solid 1px ${COLORS.BRAND_BLUE_SIX};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 16rem;
  margin-top: 0.25rem;
  position: absolute;
  overflow: scroll;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
`;

const isDropdownOptionValid = ({ value }) => {
  const { make } = JSON.parse(value);

  return !!make;
};

const buildDropdownOptions = (carObjects) =>
  _(carObjects)
    .map(({ make, model }) => ({
      value: JSON.stringify({ make: _.toLower(make), model: _.toLower(model) }),
      label: `${make} ${model}`,
    }))
    .filter(isDropdownOptionValid)
    .uniqBy((option) => option.label.toLowerCase())
    .sortBy((option) => option.label.toLowerCase())
    .value();

const scrollToRef = (ref, isMobileDevice) => {
  const scrollIsSupported = 'scrollBehavior' in document.documentElement.style;

  if (scrollIsSupported && isMobileDevice) {
    ref.current.scrollIntoView();
  }
};

const onChange = ({ make, model }) => {
  if (make && model) {
    logTrackingEvent({
      event: 'searchMakeModel',
      make,
      model,
    });

    navigateToVehiclePage(make, model);
  }
};

const InputForm = ({ onFocus, onKeyDown, onInput, selectedMake }) => {
  const ariaLabel = useMemo(() => {
    if (selectedMake) {
      const { make, model } = JSON.parse(selectedMake.value);

      return `${make} ${model} selected.`;
    }

    return null;
  }, [selectedMake]);

  return (
    <StyledForm>
      <SearchIcon name={iconNames.magnifyingGlass} />
      <StyledSelect
        aria-live="assertive"
        aria-label={ariaLabel}
        placeholder="Enter a make or model"
        onFocus={onFocus}
        onInput={(event) => {
          onInput(event.target.value);
        }}
        onKeyDown={onKeyDown}
      />
    </StyledForm>
  );
};

const AutocompleteOption = ({ children, isSelected, onClick }) => {
  const optionRef = useRef();

  useEffect(() => {
    if (isSelected) {
      optionRef.current.scrollIntoView(false);
    }
  }, [isSelected]);

  return (
    <StyledOption type="button" onClick={onClick} isSelected={isSelected} ref={optionRef}>
      {children}
    </StyledOption>
  );
};

const SearchInput = ({ isLoading, refine, hits: autoCompleteResults }) => {
  const autoCompleteOptions = useMemo(() => (isLoading ? [] : buildDropdownOptions(autoCompleteResults)), [
    isLoading,
    autoCompleteResults,
  ]);

  const [selectedMakeIndex, setSelectedMakeIndex] = useState(() => {
    if (autoCompleteOptions.length) {
      return 0;
    }

    return null;
  });

  useEffect(() => {
    if (selectedMakeIndex > autoCompleteOptions.length - 1) {
      setSelectedMakeIndex(autoCompleteOptions.length - 1);
    }
  }, [autoCompleteOptions.length]);

  const navigateToVehicle = useCallback((option) => {
    if (option?.value) {
      const { make, model } = JSON.parse(option.value);

      onChange({ make, model });
    }
  }, []);

  const [listVisible, setListVisible] = useState(false);

  const toggleListVisible = () => {
    setListVisible(!listVisible);
  };

  const ref = useClickOutside(toggleListVisible, listVisible);

  return (
    <SearchContainer ref={ref}>
      <InputContainer role="alert" aria-label={`${autoCompleteOptions.length} results available`}>
        <InputForm
          onInput={(e) => {
            setListVisible(e.length > 0);
            refine(e);
          }}
          selectedMake={selectedMakeIndex === null ? null : autoCompleteOptions[selectedMakeIndex]}
          onBlur={toggleListVisible}
          onFocus={(e) => e.target.value.length > 0 && setListVisible(true)}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'Enter': {
                e.stopPropagation();

                if (selectedMakeIndex !== null && autoCompleteOptions[selectedMakeIndex]) {
                  const { value } = autoCompleteOptions[selectedMakeIndex];

                  const { make, model } = JSON.parse(value);

                  navigateToVehiclePage(make, model);
                }

                break;
              }
              case 'ArrowDown': {
                e.stopPropagation();
                setSelectedMakeIndex(selectedMakeIndex === autoCompleteOptions.length - 1 ? 0 : selectedMakeIndex + 1);
                break;
              }
              case 'ArrowUp': {
                e.stopPropagation();
                setSelectedMakeIndex(selectedMakeIndex === 0 ? autoCompleteOptions.length - 1 : selectedMakeIndex - 1);
                break;
              }
              default:
            }
          }}
        />
      </InputContainer>
      {autoCompleteOptions.length > 0 && listVisible && (
        <OptionContainer>
          {autoCompleteOptions.map((option, index) => (
            <AutocompleteOption
              key={option.value}
              isSelected={selectedMakeIndex === index}
              onClick={() => navigateToVehicle(option)}
            >
              {option.label} <OptionIcon name={iconNames.linkActive} />
            </AutocompleteOption>
          ))}
        </OptionContainer>
      )}
      {!isLoading && autoCompleteOptions.length === 0 && (
        <ErrorText>We couldn&apos;t find any search results, please try a different search term</ErrorText>
      )}
    </SearchContainer>
  );
};

const ConnectedSearchInput = connectHits(SearchInput);

export { isDropdownOptionValid, buildDropdownOptions, scrollToRef, ConnectedSearchInput, SearchInput };
