import { useTheme } from '@mui/material/styles';
import { InstantSearch } from 'react-instantsearch-dom';
import { NumberPlateSearchBox } from './number-plate-tab-panel';
import { MakeModelSearchBox } from './make-model-tab-panel';
import { useRef, useState } from 'react';
import algoliasearch from 'algoliasearch';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { SPACE } from '@/constants';

const searchClient = algoliasearch(
  `${process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID}`,
  `${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY}`,
  {}
);

export const MinimalSearch = () => {
  const searchTopRef = useRef();
  const [value, setValue] = useState('make-model');
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <InstantSearch indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME ?? ''} searchClient={searchClient}>
      <FormControl>
        <RadioGroup aria-label="Search Type" name="search-type" value={value} onChange={handleChange}>
          <Grid direction="row">
            <FormControlLabel
              value="make-model"
              control={<Radio size="small" />}
              label={<strong>Make & Model</strong>}
            />
            <FormControlLabel
              value="rego"
              control={<Radio size={isMobileDevice ? 'medium' : 'small'} />}
              label={<strong>Victorian Number Plate</strong>}
            />
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box
        display="grid"
        justifyItems={isMobileDevice ? 'center' : 'start'}
        gap={SPACE.X1}
        gridTemplateColumns={isMobileDevice ? '100%' : '100% 50%'}
        width={isMobileDevice ? '100%' : '45%'}
        marginTop={SPACE.X0}
      >
        {value == 'rego' && <NumberPlateSearchBox errorSecondaryPosition />}
        {value == 'make-model' && <MakeModelSearchBox searchTopRef={searchTopRef} isMobileDevice={isMobileDevice} />}
      </Box>
    </InstantSearch>
  );
};
