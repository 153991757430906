import _ from 'lodash';

import initialState from './state';

const addBodyStyle = (state, bodyStyle) => {
  return {
    ...state,
    selectedBodyStyles: _.concat(state.selectedBodyStyles, bodyStyle),
  };
};

const addLifestyle = (state, lifestyle) => {
  return {
    ...state,
    selectedLifestyles: _.concat(state.selectedLifestyles, lifestyle),
  };
};

const addMake = (state, make) => {
  return {
    ...state,
    selectedMakes: _.concat(state.selectedMakes, make),
  };
};

const addSafetyFeature = (state, safetyFeature) => {
  return {
    ...state,
    selectedSafetyFeatures: _.concat(state.selectedSafetyFeatures, safetyFeature),
  };
};

const addStarRating = (state, starRating) => {
  return {
    ...state,
    selectedStarRatings: _.concat(state.selectedStarRatings, starRating),
  };
};

const addPowerType = (state, powerType) => {
  return {
    ...state,
    selectedPowerTypes: _.concat(state.selectedPowerTypes, powerType),
  };
};

const removeLifestyle = (state, lifestyle) => {
  return {
    ...state,
    selectedLifestyles: _.filter(state.selectedLifestyles, (item) => item !== lifestyle),
  };
};

const removeSafetyFeature = (state, safetyFeature) => {
  return {
    ...state,
    selectedSafetyFeatures: _.filter(state.selectedSafetyFeatures, (item) => item !== safetyFeature),
  };
};

const removeStarRating = (state, starRating) => {
  return {
    ...state,
    selectedStarRatings: _.filter(state.selectedStarRatings, (item) => item !== starRating),
  };
};

const removeBodyStyle = (state, bodyStyle) => {
  return {
    ...state,
    selectedBodyStyles: _.filter(state.selectedBodyStyles, (item) => item !== bodyStyle),
  };
};

const removeMake = (state, make) => {
  return {
    ...state,
    selectedMakes: _.filter(state.selectedMakes, (item) => item !== make),
  };
};

const removePowerType = (state, powerType) => {
  return {
    ...state,
    selectedPowerTypes: _.filter(state.selectedPowerTypes, (item) => item !== powerType),
  };
};

const updateLifestyles = (state, selectedLifestyles) => {
  return {
    ...state,
    selectedLifestyles,
  };
};

const updateMakes = (state, selectedMakes) => {
  return {
    ...state,
    selectedMakes,
  };
};

const updateSafetyFeatures = (state, selectedSafetyFeatures) => {
  return {
    ...state,
    selectedSafetyFeatures,
  };
};

const updateSelectedBodyStyles = (state, selectedBodyStyles) => {
  return {
    ...state,
    selectedBodyStyles,
  };
};

const updateSelectedPriceRange = (state, selectedPriceRange) => {
  return {
    ...state,
    selectedPriceRange,
  };
};

const updateUiSelectedPriceRange = (state, selectedPriceRange) => {
  return {
    ...state,
    uiSelectedPriceRange: selectedPriceRange,
  };
};

const updateSelectedYearRange = (state, selectedYearRange) => {
  return {
    ...state,
    selectedYearRange,
  };
};

const updateUiSelectedYearRange = (state, selectedYearRange) => {
  return {
    ...state,
    uiSelectedYearRange: selectedYearRange,
  };
};

const updateStarRatings = (state, selectedStarRatings) => {
  return {
    ...state,
    selectedStarRatings,
  };
};

const updatePowerTypes = (state, selectedPowerTypes) => {
  return {
    ...state,
    selectedPowerTypes,
  };
};

const reset = () => _.cloneDeep(initialState);

const resetSelectedYearRange = (state) => {
  return {
    ...state,
    selectedYearRange: initialState.selectedYearRange,
    uiSelectedYearRange: initialState.selectedYearRange,
  };
};

const resetSelectedPriceRange = (state) => {
  return {
    ...state,
    selectedPriceRange: initialState.selectedPriceRange,
    uiSelectedPriceRange: initialState.selectedPriceRange,
  };
};

export default {
  addBodyStyle,
  addLifestyle,
  addMake,
  addSafetyFeature,
  addStarRating,
  addPowerType,
  removeBodyStyle,
  removeLifestyle,
  removeMake,
  removeSafetyFeature,
  removeStarRating,
  removePowerType,
  reset,
  resetSelectedPriceRange,
  resetSelectedYearRange,
  updateLifestyles,
  updateMakes,
  updateSafetyFeatures,
  updateSelectedBodyStyles,
  updateSelectedPriceRange,
  updateSelectedYearRange,
  updateStarRatings,
  updatePowerTypes,
  updateUiSelectedPriceRange,
  updateUiSelectedYearRange,
};
