import React from 'react';
import _ from 'lodash';

import { StyledBreadcrumbs, StyledCrumbLink, CrumbSeparator, Bold } from './styles';

interface Ancestor {
  title: string;
  url: string;
}

interface Entry {
  title: string;
  ancestors: Ancestor[];
}

export interface BreadcrumbsProps {
  entry: Entry;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ entry }) => {
  if (_.isNil(entry) || _.isEmpty(entry.ancestors)) {
    return null;
  }

  return (
    <StyledBreadcrumbs>
      {_.map(entry.ancestors, ({ title, url }, index) => {
        const isRootEntry = index === 0;
        return (
          <li key={title}>
            <StyledCrumbLink href={url}>{isRootEntry ? <Bold>{title}</Bold> : title}</StyledCrumbLink>
            <CrumbSeparator>{isRootEntry ? <Bold>/</Bold> : '/'}</CrumbSeparator>
          </li>
        );
      })}
      <li>{entry.title}</li>
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
