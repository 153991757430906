import _ from 'lodash';

import { priceRangeSliderMarks } from '../../../components/discovery-questions-input/utils/price-range-slider-marks';
import { yearSliderMarks } from '../../../components/discovery-filter/constants';

export default {
  selectedBodyStyles: [],
  selectedLifestyles: [],
  selectedMakes: [],
  selectedStarRatings: [],
  selectedSafetyFeatures: [],
  selectedPowerTypes: [],
  selectedPriceRange: [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value],
  uiSelectedPriceRange: [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value],
  selectedYearRange: [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value],
  uiSelectedYearRange: [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value],
};
