import _ from 'lodash';

const yearSliderMarks = _.range(1995, new Date().getFullYear() + 1).map((value) => ({ value, label: '' }));

/*
  The "all" checkboxes are treated differently to normal ones.
  Selecting it de-selects all other selections, and selecting anything else de-selects this.
  If the checkboxes are sorted alphabetically, this is excluded - always appears at the top.
  Any checkbox with this key will be treated in this fashion.
 */
const ALL_ID = 'all';

const POWER_TYPE_MAPPING = {
  Hybrid: [
    'Mild Hybrid Electric Vehicle (MHEV)',
    'Plug-in Hybrid Electric Vehicle (PHEV)',
    'Hybrid Electric Vehicle (HEV)',
  ],
  Electric: ['Battery Electric Vehicle (BEV)', 'Range Extended Battery Electric Vehicle (BEVx)'],
};

export { yearSliderMarks, ALL_ID, POWER_TYPE_MAPPING };
