import React from 'react';

import BaseContentMatrix, { basePropTypes } from '../base-content-matrix';
import { buildContentMatrixElementQueryString } from '../../utils/content-matrix';
import PageHeaderBlock from '../page-header-block';
import { MinimalSearch } from '../search/minimal';

interface PageHeaderMatrixProps extends React.ComponentProps<typeof BaseContentMatrix> {
  search?: boolean;
  SearchComponent?: typeof MinimalSearch;
}

interface PageHeaderMatrixComponent extends React.FC<PageHeaderMatrixProps> {
  query: string;
}

const CONTENT_MATRIX_MAPPING = {
  pageHeaderBlock: PageHeaderBlock,
} as const;

const PageHeaderMatrix: PageHeaderMatrixComponent = ({ search = false, SearchComponent = MinimalSearch, ...props }) => {
  const elementMapping = {
    pageHeaderBlock: (blockProps: React.ComponentProps<typeof PageHeaderBlock>) => (
      <PageHeaderBlock {...blockProps} search={search} SearchComponent={SearchComponent} />
    ),
  };

  return (
    <BaseContentMatrix
      {...{
        ...props,
        elementMapping,
      }}
    />
  );
};

PageHeaderMatrix.query = `
  pageHeaderMatrix {
    typeName: __typename
    ${buildContentMatrixElementQueryString(CONTENT_MATRIX_MAPPING)}
  }
`;

export default PageHeaderMatrix;
export { CONTENT_MATRIX_MAPPING };
