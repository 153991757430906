import { CURATED_FILTERS } from '../../constants/curated-filters';
import { getCuratedFilterUrl } from '../../utils/get-curated-filter-url';

interface DiscoveryLink {
  linkText: string;
  href: string;
  price?: string;
}

const CART_MAXIMUM: number = 6;

const DISCOVERY_LINKS: Record<string, DiscoveryLink[]> = {
  NO_VEHICLE: [
    {
      linkText: CURATED_FILTERS.P_PLATER_APPROVED.title,
      href: getCuratedFilterUrl('P_PLATER_APPROVED'),
      price: CURATED_FILTERS.P_PLATER_APPROVED.price,
    },
    {
      linkText: CURATED_FILTERS.SAFE_CARS_FOR_FAMILIES.title,
      href: getCuratedFilterUrl('SAFE_CARS_FOR_FAMILIES'),
      price: CURATED_FILTERS.SAFE_CARS_FOR_FAMILIES.price,
    },
    {
      linkText: CURATED_FILTERS.SUVS.title,
      href: getCuratedFilterUrl('SUVS'),
      price: CURATED_FILTERS.SUVS.price,
    },
    {
      linkText: CURATED_FILTERS.ELECTRIC_AND_HYBRID.title,
      href: getCuratedFilterUrl('ELECTRIC_AND_HYBRID'),
      price: CURATED_FILTERS.ELECTRIC_AND_HYBRID.price,
    },
  ],
  SINGLE_VEHICLE: [
    {
      linkText: 'Search for another car to add',
      href: '/',
    },
  ],
  MULTIPLE_VEHICLES: [
    {
      linkText: 'Add another car',
      href: '/',
    },
  ],
};

export { CART_MAXIMUM, DISCOVERY_LINKS };
